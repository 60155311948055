<template>
  <div :class="$style.report">
    <Title text="Отчет по преподавателям" position="right" />

    <div :class="$style.report__header">
      <date-picker
        v-model="date"
        range
        :lang="lang"
        placeholder="Дата"
        :editable="false"
        :formatter="momentFormat"
        @input="getReportOnNewPedagogs(1)"
        @change="clearData"
      />
    </div>

    <div v-if="pedagogsCount > 0" :class="$style.report__counter">
      <h3>Количетсво строк: {{ pedagogsCount }}</h3>

      <Button
        :class="$style.report__header__button"
        type="tertiary"
        center
        :disabled="pedagogsCount === 0 || loading"
        @click="getReportListPedagogsFile"
      >
        Скачать xlsx файл
      </Button>
    </div>

    <div v-if="pedagogsCount > 0" :class="$style.report__table">
      <div :class="$style.report__table__header">
        <div :class="$style.report__table__header_row">
          <div
            :class="$style.report__table__header_column"
            v-for="headerColumn in headerColumns"
            :key="headerColumn.id"
          >
            {{ headerColumn.title }}
            <img
              v-if="pedagogsCount > 0 && headerColumn.sortValue"
              :class="[
                $style.report__table__header_column_icon,
                { [$style.report__table__header_column_icon_active]: sortMethod === 'asc' }
              ]"
              src="@/assets/icons/arrow.svg"
              alt="arrow"
              @click="sortReportOnNewPedagogs(headerColumn.sortValue)"
            />
          </div>
        </div>
      </div>

      <div :class="$style.report__table__content">
        <div v-for="pedagog in pedagogs" :key="pedagog.id">
          <div :class="$style.report__table__content_row">
            <div :class="$style.report__table__content_column">
              {{ pedagog.id }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.role.name }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.fullName }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.phone }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.email }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.isVerified ? 'Верифицирован' : 'Не верифицирован' }}
            </div>
            <div :class="$style.report__table__content_column">{{ pedagog.subjectsNames}}</div>
            <div :class="$style.report__table__content_column">{{ pedagog.ageGroups }}</div>
            <div :class="$style.report__table__content_column">{{ pedagog.lessonsTypes}}</div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.finishOrders }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.countGrade }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ pedagog.averageGrade }}
            </div>
            <div :class="$style.report__table__content_column">
              {{ moment(pedagog.cteatedAt).format('YYYY-MM-DD HH:mm:ss') || '' }}
            </div>
            <div :class="$style.report__table__content_column">
              {{
                pedagog.firstVerificationDate && pedagog.firstVerificationDate.length > 0
                  ? moment(pedagog.firstVerificationDate).format('YYYY-MM-DD HH:mm:ss')
                  : ''
              }}
            </div>
          </div>
        </div>

        <div :class="$style.report__table__content_rowLine">
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
          <div :class="$style.report__table__content_line" />
        </div>
      </div>
    </div>

    <pagination
      v-if="pedagogsCount > 0"
      :pageCount="pageCount"
      :prevText="''"
      :click-handler="getReportOnNewPedagogs"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      :initial-page="this.$route.query.page - 1"
    />
  </div>
</template>

<script>
import Title from '@/basic/Title'
import Button from '@/basic/Button'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'

import Moment from 'moment'

import users from '@/api/users'

import paginationMixin from '@/mixins/pagination.mixin'

import 'vue2-datepicker/index.css'

const headerColumns = [
  {
    id: 1,
    title: 'id',
    sortValue: 'id'
  },
  {
    id: 2,
    title: 'Роль'
  },
  {
    id: 3,
    title: 'ФИО'
  },
  {
    id: 4,
    title: 'Телефон'
  },
  {
    id: 5,
    title: 'Почта'
  },
  {
    id: 6,
    title: 'Статус'
  },
  {
    id: 7,
    title: 'Предметы'
  },
  {
    id: 8,
    title: 'Класс'
  },
  {
    id: 9,
    title: 'Тип занятий'
  },
  {
    id: 10,
    title: 'Завершено'
  },
  {
    id: 11,
    title: 'Оценок'
  },
  {
    id: 12,
    title: 'Рейтинг'
  },
  {
    id: 13,
    title: 'Дата регистрации'
  },
  {
    id: 14,
    title: 'Дата активации'
  }
]

export default {
  name: 'TeachersReport',
  components: {
    Title,
    Button,
    DatePicker
  },
  mixins: [paginationMixin],
  data() {
    return {
      lang: ru,
      headerColumns,

      sortMethod: 'desc',

      loading: false,

      date: [],
      pedagogs: [],
      pedagogsCount: 0,

      momentFormat: {
        stringify: (date) => {
          return date ? this.moment(date).format('DD-MM-YYYY') : ''
        }
      }
    }
  },
  computed: {
    moment() {
      return Moment
    },
    startDate() {
      return this.date.length && this.moment(this.date[0]).format('YYYY-MM-DD')
    },
    endDate() {
      return this.date.length && this.moment(this.date[1]).format('YYYY-MM-DD')
    }
  },
  mounted() {
    if (this.$route.query.page) this.$router.push('/teachersReport')
  },
  methods: {
    async getReportOnNewPedagogs(num) {
      if (
        !this.date.length ||
        (this.startDate === 'Invalid date' && this.endDate === 'Invalid date')
      ) {
        return
      }
      try {
        const page = num ?? parseInt(this.$route.query.page)

        const { data } = await users.getReportListPedagogs({
          filter: {
            createdAt: { start: this.startDate, end: this.endDate }
          },
          pagination: {
            offset: page * 10 - 10,
            limit: 10
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        })

        if (data?.success && !data?.result?.count) {
          return this.openNotice(
            'InfoNotice',
            `По данному периоду (${this.moment(this.startDate).format(
              'DD-MM-YYYY'
            )} - ${this.moment(this.endDate).format('DD-MM-YYYY')}) педагогов не найдено.`,
            2500
          )
        }

        if (data?.success) {
          this.pedagogsCount = data.result.count
          this.pedagogs = data.result.rows

          this.setupTeacherReportPagination(this.pedagogs, data.result.count)
          this.$router.push(`?page=${page}`).catch(() => {})
        }
      } catch (e) {
        console.warn(e)
        this.openNotice('ErrorNotice', `Ошибка получения данных с сервера!`, 2500)
      }
    },

    async sortReportOnNewPedagogs() {
      this.toggleSortMethod()

      const page = this.$route.query.page ? parseInt(this.$route.query.page) : 1

      try {
        const { data } = await users.getReportListPedagogs({
          filter: {
            createdAt: { start: this.startDate, end: this.endDate }
          },
          pagination: {
            offset: page * 10 - 10,
            limit: 10
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        })

        if (data?.success) {
          this.pedagogsCount = data.result.count
          this.pedagogs = data.result.rows

          this.setupTeacherReportPagination(this.pedagogs, data.result.count)
          this.$router.push(`?page=${page}`).catch(() => {})
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения данных с сервера!`, 2500)
      }
    },

    async getReportListPedagogsFile() {
      try {
        this.loading = true

        const resp = await users.getReportListPedagogsFile({
          filter: {
            createdAt: { start: this.startDate, end: this.endDate }
          },
          order: {
            field: 'id',
            by: this.sortMethod
          }
        })

        if (resp?.status === 200) {
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(resp.data)
          link.download = `Отчет по преподавателям с ${this.moment(this.startDate).format(
            'DD-MM-YYYY'
          )} по ${this.moment(this.endDate).format('DD-MM-YYYY')}.xlsx`
          link.click()
        }
      } catch (error) {
        console.warn(error)
        this.openNotice('ErrorNotice', `Ошибка получения файла с сервера!`, 2500)
      } finally {
        this.loading = false
      }
    },

    clearData() {
      this.pedagogsCount = 0
      this.pedagogs = []
      this.$router.push('/teachersReport').catch(() => {})
    },

    openNotice(type, text, timer) {
      this.$store.commit(
        'setNoticeData',
        `<p style="margin: 2rem 3rem; font-size: 14px">${text}</p>`
      )

      this.$store.commit('setNotice', type)

      setTimeout(() => {
        this.$store.commit('setNoticeData', null)
        this.$store.commit('setNotice', null)
      }, timer)
    },

    toggleSortMethod() {
      this.sortMethod = this.sortMethod === 'asc' ? 'desc' : 'asc'
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>

<style module lang="scss">
@import '@/assets/styles/colors.scss';

.report {
  &__header {
    display: flex;
    align-items: center;

    &__button {
      width: 200px;
      margin-left: 30px;
      padding: 5px 15px !important;
    }
  }

  &__counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 0 1rem;
  }

  &__table {
    width: 100%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 0.95rem;
        font-weight: 700;
        &_icon {
          cursor: pointer;
          padding: 10px;
          transition: all 0.3s ease;

          &_active {
            transform: rotate(180deg);
          }
        }

        &:first-child {
          width: 3.7%;
        }

        &:nth-child(n + 2) {
          width: 7.4%;
          justify-content: center;
        }
        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        border-bottom: 1px solid $table-border;
      }

      &_column {
        display: flex;
        align-items: center;
        padding: 0.7rem 1rem;
        font-size: 0.875rem;
        font-weight: 500;
        &:first-child {
          width: 3.7%;
          justify-content: center;
        }
        &:nth-child(n + 2) {
          width: 7.4%;
          padding: 0.2rem;
          justify-content: center;
        }
        &:nth-child(5) {
          word-break: break-all;
        }
        &:nth-child(6) {
          word-break: break-all;
        }
        &:nth-child(7) {
          padding: 0.2rem 0.7rem;
        }
        &:nth-child(13) {
          padding: 0.2rem 0.7rem;
        }
        &:nth-child(14) {
          padding: 0.2rem 0.7rem;
        }
        &:last-child {
          border: none;
        }
      }

      &_rowLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;
        word-break: break-all;
        &:first-child {
          width: 3.7%;
        }

        &:nth-child(n + 2) {
          width: 7.4%;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
}
</style>
